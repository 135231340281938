import classNames from 'classnames';
import {Component, Children, ReactElement, EventHandler, Ref, RefObject} from "react";

import { deepEqual } from "fast-equals";

import {GridItem} from "./GridItem";
import {
    Layout,
    LayoutItem,
    synchronizeLayoutWithChildren,
    compact,
    moveElement,
    childrenEqual
} from "./utils";

import {
    getLayoutItem,
    GridResizeEvent,
    GridDragEvent,
    DragOverEvent,
    DroppingPosition,
    bottom,
    cloneLayoutItem,
    fastRGLPropsEqual,
    withLayoutItem,
    ResizeHandleAxis,
    ResizeHandle,
    EventCallback
} from "react-grid-layout/build/utils";

const layoutClassName = "react-grid-layout";

type StoneMasonState = {
    children: ReactElement[],
    activeDrag: LayoutItem | null,
    layout: Layout,
    cols: number,
    mounted: boolean,
    oldDragItem: LayoutItem | null,
    oldLayout: Layout | null,
    oldResizeItem: LayoutItem | null,
    resizing: boolean,
    droppingDOMNode?: ReactElement | null,
    droppingPosition?: DroppingPosition,
    // Mirrored props
    propsLayout?: Layout
};

type StoneMasonProps = {
    children: ReactElement[],
    className: string,
    style: Object,
    width: number,
    cols: number,
    draggableHandle: string,
    layout: Layout,
    margin: [number, number],
    containerPadding?: [number, number] | null,
    rowHeight: number,
    maxRows: number,
    useCSSTransforms: boolean,
    droppingItem: LayoutItem,
    resizeHandles: ResizeHandleAxis,
    resizeHandle?: ResizeHandle,
    innerRef?: RefObject<HTMLDivElement>,

    // Callbacks
    onDrag: EventCallback,
    onDragStart: EventCallback,
    onDragStop: EventCallback,
    onResize: EventCallback,
    onResizeStart: EventCallback,
    onResizeStop: EventCallback,
    onDropDragOver: (e: DragOverEvent) => ({w?: number, h?: number} | false),
    onDrop: (layout: Layout, item: LayoutItem | null, e: Event) => void,
}

export class StoneMason extends Component<StoneMasonProps, StoneMasonState> {
    public packer;
    private dragTimeout;

    static defaultProps: Partial<StoneMasonProps> = {
        cols: 12,
        className: "",
        style: {},
        draggableHandle: "",
        containerPadding: null,
        rowHeight: 150,
        maxRows: Infinity, // infinite vertical growth
        layout: [],
        margin: [10, 10],
        useCSSTransforms: true,
        // @ts-ignore
        droppingItem: {
            i: "__dropping-elem__",
            h: 1,
            w: 1
        },
        resizeHandles: ["se"],
        onDragStart: () => {},
        onDrag: () => {},
        onDragStop: () => {},
        onResizeStart: () => {},
        onResize: () => {},
        onResizeStop: () => {},
        onDrop: () => {},
        onDropDragOver: () => false,
    };

    dragEnterCounter: number = 0;

    constructor(props) {
        super(props);

        this.state = {
            children: [],
            activeDrag: null,
            layout: synchronizeLayoutWithChildren(props.children, props.cols),
            cols: props.cols,
            mounted: false,
            oldDragItem: null,
            oldLayout: null,
            oldResizeItem: null,
            resizing: false,
            droppingDOMNode: null,
        }
    }

    componentDidMount() {
        this.setState({ mounted: true });
    }

    static getDerivedStateFromProps(
        nextProps: StoneMasonProps,
        prevState: StoneMasonState
    ): Partial<StoneMasonState> | null {
        let newLayoutBase;

        if (prevState.activeDrag) {
            return null;
        }

        if (
            !childrenEqual(nextProps.children as ReactElement[], prevState.children) ||
            nextProps.cols !== prevState.cols
        ) {
            // If children change, also regenerate the layout. Use our state
            // as the base in case because it may be more up to date than
            // what is in props.
            newLayoutBase = prevState.layout;
        }

        // We need to regenerate the layout.
        if (newLayoutBase) {
            const newLayout = synchronizeLayoutWithChildren(
                nextProps.children,
                nextProps.cols
            );

            return {
                layout: newLayout,
                // We need to save these props to state for using
                // getDerivedStateFromProps instead of componentDidMount (in which we would get extra rerender)
                children: nextProps.children,
                propsLayout: nextProps.layout,
                cols: nextProps.cols,
            };
        }

        return null;
    }

    shouldComponentUpdate(nextProps: StoneMasonProps, nextState: StoneMasonState): boolean {
        return (
            // NOTE: this is almost always unequal. Therefore the only way to get better performance
            // from SCU is if the user intentionally memoizes children. If they do, and they can
            // handle changes properly, performance will increase.
            this.props.children !== nextProps.children ||
            !fastRGLPropsEqual(this.props, nextProps, deepEqual) ||
            this.state.activeDrag !== nextState.activeDrag ||
            this.state.mounted !== nextState.mounted ||
            this.state.droppingPosition !== nextState.droppingPosition
        );
    }

    /**
     * Calculates a pixel value for the container.
     * @return {String} Container height in pixels.
     */
    containerHeight(): string | null {
        const nbRow = bottom(this.state.layout);
        const containerPaddingY = this.props.containerPadding
            ? this.props.containerPadding[1]
            : this.props.margin[1];
        return (
            nbRow * this.props.rowHeight +
            (nbRow - 1) * this.props.margin[1] +
            containerPaddingY * 2 +
            "px"
        );
    }

    /**
     * When dragging starts
     * @param {String} i Id of the child
     * @param {Number} x X position of the move
     * @param {Number} y Y position of the move
     * @param {Event} e The mousedown event
     * @param {Element} node The current dragging DOM element
     */
    onDragStart: (i: string, x: number, y: number, GridDragEvent) => void = (
        i: string,
        x: number,
        y: number,
        { e, node }: GridDragEvent
    ) => {
        if (this.dragTimeout) clearTimeout(this.dragTimeout);
        const { layout } = this.state;
        const l = getLayoutItem(layout, i);
        if (!l) return;

        // Create placeholder (display only)
        const placeholder = {
            w: l.w,
            h: l.h,
            x: l.x,
            y: l.y,
            placeholder: true,
            i: i
        };

        this.setState({
            oldDragItem: cloneLayoutItem(l),
            oldLayout: layout,
            activeDrag: placeholder
        });

        return this.props.onDragStart(layout, l, l, null, e, node);
    };

    /**
     * Each drag movement create a new dragelement and move the element to the dragged location
     * @param {String} i Id of the child
     * @param {Number} x X position of the move
     * @param {Number} y Y position of the move
     * @param {Event} e The mousedown event
     * @param {Element} node The current dragging DOM element
     */
    onDrag: (i: string, x: number, y: number, GridDragEvent) => void = (
        i,
        x,
        y,
        { e, node }
    ) => {
        if (this.dragTimeout) clearTimeout(this.dragTimeout);
        this.dragTimeout = setTimeout(() => {
            const { oldDragItem } = this.state;
            let { layout } = this.state;
            const { cols } = this.props;
            const l = getLayoutItem(layout, i);
            if (!l) return;

            // Create placeholder (display only)
            const placeholder = {
                w: l.w,
                h: l.h,
                x: l.x,
                y: l.y,
                placeholder: true,
                i: i
            };

            // Move the element to the dragged location.
            const isUserAction = true;
            layout = moveElement(
                layout,
                l,
                x,
                y,
                isUserAction,
                false,
                cols,
            );

            this.props.onDrag(layout, oldDragItem, l, placeholder, e, node);

            l.static = true; // temporarily set dragged item to static so its position is respected above all else
            const newLayout = compact(layout, cols);
            getLayoutItem(newLayout, i).static = false; // remove static designation - if the item's placement can't
            // stick because of packing, that will be handled in onDragStop

            this.setState({
                layout: newLayout,
                activeDrag: placeholder
            });
        }, 45);
    };

    /**
     * When dragging stops, figure out which position the element is closest to and update its x and y.
     * @param  {String} i Index of the child.
     * @param {Number} x X position of the move
     * @param {Number} y Y position of the move
     * @param {Event} e The mousedown event
     * @param {Element} node The current dragging DOM element
     */
    onDragStop: (i: string, x: number, y: number, GridDragEvent) => void = (
        i,
        x,
        y,
        { e, node }
    ) => {
        if (!this.state.activeDrag) return;
        if (this.dragTimeout) clearTimeout(this.dragTimeout);

        const { oldDragItem } = this.state;
        let { layout } = this.state;
        const { cols } = this.props;
        const l = getLayoutItem(layout, i);
        if (!l) return;

        // Move the element here
        const isUserAction = true;
        layout = moveElement(
            layout,
            l,
            x,
            y,
            isUserAction,
            false,
            cols,
        );

        // Set state
        const newLayout = compact(layout, cols);

        this.props.onDragStop(newLayout, oldDragItem, l, null, e, node);

        this.setState({
            activeDrag: null,
            layout: newLayout,
            oldDragItem: null,
            oldLayout: null
        });
    };

    onResizeStart: (i: string, w: number, h: number, e: GridResizeEvent) => void = (
        i,
        w,
        h,
        { e, node }
    ) => {
        const { layout } = this.state;
        const l = getLayoutItem(layout, i);
        if (!l) return;

        this.setState({
            oldResizeItem: cloneLayoutItem(l),
            oldLayout: this.state.layout,
            resizing: true
        });

        this.props.onResizeStart(layout, l, l, null, e, node);
    };

    onResize: (i: string, w: number, h: number, e: GridResizeEvent) => void = (
        i,
        w,
        h,
        { e, node, size, handle }
    ) => {
        const { oldResizeItem } = this.state;
        const { layout } = this.state;
        const { cols } = this.props;

        let shouldMoveItem = false;
        let finalLayout;
        let x;
        let y;

        const [newLayout, l] = withLayoutItem(layout, i, l => {
            x = l.x;
            y = l.y;
            if (["sw", "w", "nw", "n", "ne"].indexOf(handle) !== -1) {
                if (["sw", "nw", "w"].indexOf(handle) !== -1) {
                    x = l.x + (l.w - w);
                    w = l.x !== x && x < 0 ? l.w : w;
                    x = x < 0 ? 0 : x;
                }

                if (["ne", "n", "nw"].indexOf(handle) !== -1) {
                    y = l.y + (l.h - h);
                    h = l.y !== y && y < 0 ? l.h : h;
                    y = y < 0 ? 0 : y;
                }

                shouldMoveItem = true;
            }

            l.w = w;
            l.h = h;

            return l;
        });

        // Shouldn't ever happen, but typechecking makes it necessary
        if (!l) return;

        finalLayout = newLayout;
        if (shouldMoveItem) {
            // Move the element to the new position.
            const isUserAction = true;
            finalLayout = moveElement(
                newLayout,
                l,
                x,
                y,
                isUserAction,
                false,
                cols,
            );
        }

        // Create placeholder element (display only)
        const placeholder = {
            w: l.w,
            h: l.h,
            x: l.x,
            y: l.y,
            static: true,
            i: i
        };

        this.props.onResize(finalLayout, oldResizeItem, l, placeholder, e, node);

        // Re-compact the newLayout and set the drag placeholder.
        this.setState({
            layout: compact(finalLayout, cols),
            activeDrag: placeholder
        });
    };

    onResizeStop: (i: string, w: number, h: number, e: GridResizeEvent) => void = (
        i,
        w,
        h,
        { e, node }
    ) => {
        const { layout, oldResizeItem } = this.state;
        const { cols } = this.props;
        const l = getLayoutItem(layout, i);

        // Set state
        const newLayout = compact(layout, cols);

        this.props.onResizeStop(newLayout, oldResizeItem, l, null, e, node);

        this.setState({
            activeDrag: null,
            layout: newLayout,
            oldResizeItem: null,
            oldLayout: null,
            resizing: false
        });
    };

    /**
     * Create a placeholder object.
     * @return {Element} Placeholder div.
     */
    placeholder(): ReactElement | null {
        const { activeDrag } = this.state;
        if (!activeDrag) return null;
        const {
            width,
            cols,
            margin,
            containerPadding,
            rowHeight,
            maxRows,
            useCSSTransforms,
        } = this.props;

        // {...this.state.activeDrag} is pretty slow, actually
        return (
            // @ts-ignore
            <GridItem
                w={activeDrag.w}
                h={activeDrag.h}
                x={activeDrag.x}
                y={activeDrag.y}
                i={activeDrag.i}
                className={`react-grid-placeholder ${
                    this.state.resizing ? "placeholder-resizing" : ""
                }`}
                containerWidth={width}
                cols={cols}
                margin={margin}
                containerPadding={containerPadding || margin}
                maxRows={maxRows}
                rowHeight={rowHeight}
                isDraggable={false}
                isResizable={false}
                useCSSTransforms={useCSSTransforms}
            >
                <div />
            </GridItem>
        );
    }

    /**
     * Given a grid item, set its style attributes & surround in a <Draggable>.
     * @param  {Element} child React element.
     * @return {Element}       Element wrapped in draggable and properly placed.
     */
    processGridItem(
        child: ReactElement,
        isDroppingItem?: boolean
    ): ReactElement | null {
        if (!child || !child.key) return null;
        const l = getLayoutItem(this.state.layout, String(child.key));
        if (!l) return null;
        const {
            width,
            cols,
            margin,
            containerPadding,
            rowHeight,
            maxRows,
            useCSSTransforms,
            draggableHandle,
            resizeHandles,
            resizeHandle
        } = this.props;
        const { mounted, droppingPosition } = this.state;

        return (
            <GridItem
                containerWidth={width}
                cols={cols}
                margin={margin}
                containerPadding={containerPadding || margin}
                maxRows={maxRows}
                rowHeight={rowHeight}
                handle={draggableHandle}
                onDragStop={this.onDragStop}
                onDragStart={this.onDragStart}
                onDrag={this.onDrag}
                onResizeStart={this.onResizeStart}
                onResize={this.onResize}
                onResizeStop={this.onResizeStop}
                isDraggable={l.isDraggable}
                isResizable={l.isResizable}
                useCSSTransforms={useCSSTransforms && mounted}
                usePercentages={!mounted}
                w={l.w}
                h={l.h}
                x={l.x}
                y={l.y}
                i={l.i}
                minH={l.minH}
                minW={l.minW}
                maxH={l.maxH}
                maxW={l.maxW}
                static={l.static}
                droppingPosition={isDroppingItem ? droppingPosition : undefined}
                resizeHandles={resizeHandles}
                resizeHandle={resizeHandle}
            >
                {child}
            </GridItem>
        );
    }

    removeDroppingPlaceholder: () => void = () => {
        const { droppingItem, cols } = this.props;
        const { layout } = this.state;

        const newLayout = compact(
            layout.filter(l => l.i !== droppingItem.i),
            cols,
        );

        this.setState({
            layout: newLayout,
            droppingDOMNode: null,
            activeDrag: null,
            droppingPosition: undefined
        });
    };

    onDragLeave: EventHandler<any> = e => {
        e.preventDefault(); // Prevent any browser native action
        e.stopPropagation();
        this.dragEnterCounter--;

        // onDragLeave can be triggered on each layout's child.
        // But we know that count of dragEnter and dragLeave events
        // will be balanced after leaving the layout's container
        // so we can increase and decrease count of dragEnter and
        // when it'll be equal to 0 we'll remove the placeholder
        if (this.dragEnterCounter === 0) {
            this.removeDroppingPlaceholder();
        }
    };

    onDragEnter: EventHandler<any> = e => {
        e.preventDefault(); // Prevent any browser native action
        e.stopPropagation();
        this.dragEnterCounter++;
    };

    onDrop: EventHandler<any> = (e: Event) => {
        e.preventDefault(); // Prevent any browser native action
        e.stopPropagation();
        const { droppingItem } = this.props;
        const { layout } = this.state;
        const item = layout.find(l => l.i === droppingItem.i) as LayoutItem;

        // reset dragEnter counter on drop
        this.dragEnterCounter = 0;

        this.removeDroppingPlaceholder();

        this.props.onDrop(layout, item, e);
    };

    render() {
        const { className, style, innerRef } = this.props;

        const mergedClassName = classNames(layoutClassName, className);
        const mergedStyle: object = {
            height: this.containerHeight(),
            ...style
        };

        return (
            <div
                ref={innerRef}
                className={mergedClassName}
                style={mergedStyle}
            >
                {Children.map(this.props.children, child =>
                    this.processGridItem(child as ReactElement)
                )}
                {this.placeholder()}
            </div>
        );
    }
}