import {ReactElement, Children} from "react";
import { deepEqual } from "fast-equals";
import {Rect} from "./rect";
import {Packer, sorters} from "./packer";

export type Size = { width: number, height: number };

export type GridResizeEvent = {
    e: Event,
    node: HTMLElement,
    size: Size,
    handle: string
};

export type ResizeHandleAxis = "s" | "w" | "e" | "n" | "sw" | "nw" | "se" | "ne";

export type LayoutItem = {
    w: number,
    h: number,
    x: number,
    y: number,
    i: string,
    minW?: number,
    minH?: number,
    maxW?: number,
    maxH?: number,
    moved?: boolean,
    static?: boolean,
    isDraggable?: boolean,
    isResizable?: boolean,
    resizeHandles?: Array<ResizeHandleAxis>,
    isBounded?: boolean
};

export type Layout = Array<LayoutItem>;

export function synchronizeLayoutWithChildren(children: ReactElement[], cols: number): Layout {
    // use child props to build a layout
    const layout: Layout = [];
    Children.forEach(children, (child, index) => {
        if (!child) return;

        const locked = child.props.static;
        const layoutItem: LayoutItem = {
            i: String(child.key),
            w: child.props.w,
            h: child.props.h,
            static: locked,
            x: locked ? child.props.x : undefined,
            y: locked ? child.props.y : undefined,
            minW: child.props.minH,
            minH: child.props.minH,
            maxW: child.props.maxH,
            maxH: child.props.maxH,
            isDraggable: child.props.isDraggable,
            isResizable: child.props.isResizable,
        }
        layout.push(layoutItem);
    });
    return compact(layout, cols);
}

export function childrenEqual(a: ReactElement[], b: ReactElement[]): boolean {
    return (
        deepEqual(
            Children.map(a, c => c?.key),
            Children.map(b, c => c?.key)
        ) &&
        deepEqual(
            Children.map(a, c => ({x: c?.props.x, y: c?.props.y, w: c?.props.w, h: c?.props.h, static: c?.props.static})),
            Children.map(b, c => ({x: c?.props.x, y: c?.props.y, w: c?.props.w, h: c?.props.h, static: c?.props.static}))
        )
    );
}

function layoutItemToRect(item: Partial<LayoutItem>) {
    return new Rect({
        x: item.x,
        y: item.y,
        width: item.w,
        height: item.h,
    })
}

function sortLayoutItems(layout: Readonly<Layout>): Layout {
    return layout.slice(0).sort(sorters.downwardLeftToRight);
}

export function compact (layout: Readonly<Layout>, cols): Layout {
    const packer = new Packer(cols, Infinity, "downwardLeftToRight");
    const layoutToReturn: Layout = Array(layout.length);
    const sorted = sortLayoutItems(layout);

    for (let i = 0, len = sorted.length; i < len; i++) {
        let l = sorted[i];

        if (l.static) {
            packer.placed(layoutItemToRect(l));
            // Add to output array to make sure they still come out in the right order.
            layoutToReturn[i] = {
                ...l,
                moved: false, // Clear moved flag, if it exists.
            };
        }
    }

    for (let i = 0, len = sorted.length; i < len; i++) {
        let l = sorted[i];

        if (!l.static) {
            // Don't move static elements
            const rect = layoutItemToRect(l);
            packer.pack(rect);
            // Add to output array to make sure they still come out in the right order.
            layoutToReturn[i] = {
                ...l,
                x: rect.x,
                y: rect.y,
                w: rect.width,
                h: rect.height,
                moved: false, // Clear moved flag, if it exists.
            };
        }
    }

    return layoutToReturn;
}

/**
 * Move an element. Responsible for doing cascading movements of other elements.
 *
 * Modifies layout items.
 *
 * @param  {Array}      layout            Full layout to modify.
 * @param  {LayoutItem} l                 element to move.
 * @param  {Number}     [x]               X position in grid units.
 * @param  {Number}     [y]               Y position in grid units.
 */
export function moveElement(
    layout: Layout,
    l: LayoutItem,
    x: number | undefined,
    y: number | undefined,
    isUserAction: boolean | undefined,
    preventCollision: boolean,
    cols: number,
): Layout {
    // If this is static and not explicitly enabled as draggable,
    // no move is possible, so we can short-circuit this immediately.
    if (l.static && l.isDraggable !== true) return layout;

    // Short-circuit if nothing to do.
    if (l.y === y && l.x === x) return layout;

    const oldX = l.x;
    const oldY = l.y;

    // This is quite a bit faster than extending the object
    if (typeof x === "number") l.x = x;
    if (typeof y === "number") l.y = y;
    l.moved = true;

    // If this collides with anything, move it.
    // When doing this comparison, we have to sort the items we compare with
    // to ensure, in the case of multiple collisions, that we're getting the
    // nearest collision.
    let sorted = sortLayoutItems(layout);
    const movingUp =
        typeof y === "number"
            ? oldY >= y
            : typeof x === "number"
                ? oldX >= x
                : false;
    // acceptable modification of read-only array as it was recently cloned
    if (movingUp) sorted = sorted.reverse();
    const collisions = getAllCollisions(sorted, l);
    const hasCollisions = collisions.length > 0;

    // We may have collisions. We can short-circuit if we've turned off collisions or
    // allowed overlap.
    if (hasCollisions && preventCollision) {
        // If we are preventing collision but not allowing overlap, we need to
        // revert the position of this element so it goes to where it came from, rather
        // than the user's desired location.
        l.x = oldX;
        l.y = oldY;
        l.moved = false;
        return layout; // did not change so don't clone
    }

    // Move each item that collides away from this element.
    for (let i = 0, len = collisions.length; i < len; i++) {
        const collision = collisions[i];

        // Short circuit so we can't infinite loop
        if (collision.moved) continue;

        // Don't move static items - we have to move *this* element away
        if (collision.static) {
            layout = moveElementAwayFromCollision(
                layout,
                collision,
                l,
                !!isUserAction,
                cols
            );
        } else {
            layout = moveElementAwayFromCollision(
                layout,
                l,
                collision,
                !!isUserAction,
                cols
            );
        }
    }

    return layout;
}

/**
 * This is where the magic needs to happen - given a collision, move an element away from the collision.
 * We attempt to move it up if there's room, otherwise it goes below.
 *
 * @param  {Array} layout            Full layout to modify.
 * @param  {LayoutItem} collidesWith Layout item we're colliding with.
 * @param  {LayoutItem} itemToMove   Layout item we're moving.
 */
export function moveElementAwayFromCollision(
    layout: Layout,
    collidesWith: LayoutItem,
    itemToMove: LayoutItem,
    isUserAction: boolean,
    cols: number
): Layout {
    const preventCollision = !!collidesWith.static; // we're already colliding (not for static items)

    // If there is enough space above the collision to put this element, move it there.
    // We only do this on the main collision as this can get funky in cascades and cause
    // unwanted swapping behavior.
    if (isUserAction) {
        // Reset isUserAction flag because we're not in the main collision anymore.
        isUserAction = false;

        // Make a mock item so we don't modify the item here, only modify in moveElement.
        const fakeItem: LayoutItem = {
            x: Math.max(collidesWith.x - itemToMove.w, 0),
            y: itemToMove.y,
            w: itemToMove.w,
            h: itemToMove.h,
            i: itemToMove.i,
        };

        const firstCollision = getFirstCollision(layout, fakeItem);
        const collisionNorth =
            firstCollision && firstCollision.y > collidesWith.y;
        const collisionWest =
            firstCollision && collidesWith.x > firstCollision.x;

        // No collision? If so, we can go up there; otherwise, we'll end up moving down as normal
        if (!firstCollision) {
            return moveElement(
                layout,
                itemToMove,
                fakeItem.x,
                undefined,
                isUserAction,
                preventCollision,
                cols
            );
        } else if (collisionWest) {
            return moveElement(
                layout,
                collidesWith,
                itemToMove.x,
                undefined,
                isUserAction,
                preventCollision,
                cols
            );
        } else if (collisionNorth) {
            return moveElement(
                layout,
                itemToMove,
                undefined,
                collidesWith.y + 1,
                isUserAction,
                preventCollision,
                cols
            );
        }
    }

    return moveElement(
        layout,
        itemToMove,
        itemToMove.x + 1,
        undefined,
        isUserAction,
        preventCollision,
        cols
    );
}

/**
 * Returns the first item this layout collides with.
 * It doesn't appear to matter which order we approach this from, although
 * perhaps that is the wrong thing to do.
 *
 * @param  {Object} layoutItem Layout item.
 * @return {Object|undefined}  A colliding layout item, or undefined.
 */
export function getFirstCollision(
    layout: Layout,
    layoutItem: LayoutItem
): LayoutItem | void {
    for (let i = 0, len = layout.length; i < len; i++) {
        if (collides(layout[i], layoutItem)) return layout[i];
    }
}

/**
 * Given two layoutitems, check if they collide.
 */
export function collides(l1: LayoutItem, l2: LayoutItem): boolean {
    if (l1.i === l2.i) return false; // same element
    if (l1.x + l1.w <= l2.x) return false; // l1 is left of l2
    if (l1.x >= l2.x + l2.w) return false; // l1 is right of l2
    if (l1.y + l1.h <= l2.y) return false; // l1 is above l2
    if (l1.y >= l2.y + l2.h) return false; // l1 is below l2
    return true; // boxes overlap
}

export function getAllCollisions(
    layout: Layout,
    layoutItem: LayoutItem
): Array<LayoutItem> {
    return layout.filter(l => collides(l, layoutItem));
}