import {
    Component,
    PropsWithChildren,
    RefObject,
    createRef,
    ReactNode,
    cloneElement,
    Children,
    ReactElement
} from "react";
import classNames from 'classnames';
import {DraggableCore} from "react-draggable";
import { Resizable } from "react-resizable";
import {
    fastPositionEqual,
    perc,
    resizeItemInDirection,
    setTopLeft,
    setTransform,
    Position,
    ResizeHandleAxis,
    ResizeHandle
} from "react-grid-layout/build/utils";
import {
    calcGridItemPosition,
    calcXY,
    calcWH,
    clamp,
    PositionParams
} from "react-grid-layout/build/calculateUtils";

type PartialPosition = { top: number, left: number };
type GridItemCallback = (
    i: string,
    w: number,
    h: number,
    Data
) => void;

type ResizeCallbackData = {
    node: HTMLElement,
    size: Position,
    handle: ResizeHandleAxis
};

type GridItemResizeCallback = (
    e: Event,
    data: ResizeCallbackData,
    position: Position
) => void;

type GridItemProps = PropsWithChildren<{
    // General grid attributes
    cols: number,
    containerWidth: number,
    rowHeight: number,
    margin: number[],
    maxRows: number,
    containerPadding: number[],

    // These are all in grid units
    x: number,
    y: number,
    w: number,
    h: number,

    // ID is nice to have for callbacks
    minW: number,
    maxW: number,
    minH: number,
    maxH: number,
    i: string,

    // Resize handle options
    resizeHandles: ResizeHandleAxis,
    resizeHandle?: ResizeHandle,

    // Functions
    onDragStop: GridItemCallback,
    onDragStart: GridItemCallback,
    onDrag: GridItemCallback,
    onResizeStop: GridItemCallback,
    onResizeStart: GridItemCallback,
    onResize: GridItemCallback,

    // Flags
    isDraggable: boolean,
    isResizable: boolean,
    static?: boolean,

    // Use CSS transforms instead of top/left
    useCSSTransforms: boolean,
    usePercentages?: boolean,

    // Others
    className?: string,
    style?: object,
    // Selector for draggable handle
    handle?: string,
    // Current position of a dropping element
    droppingPosition: {
        e: object,
        left: number,
        top: number
    }
}>

type GridItemState = {
    resizing: { top: number, left: number, width: number, height: number } | null,
    dragging: { top: number, left: number } | null,
    className: string
}

export class GridItem extends Component<PropsWithChildren<GridItemProps>, GridItemState> {
    static defaultProps = {
        className: "",
        handle: "",
        minH: 1,
        minW: 1,
        maxH: Infinity,
        maxW: Infinity,
    };

    constructor(props) {
        super(props);

        this.state = {
            resizing: null,
            dragging: null,
            className: "",
        }
    }

    elementRef: RefObject<HTMLDivElement> = createRef();

    shouldComponentUpdate(nextProps: GridItemProps, nextState: GridItemState): boolean {
        // We can't deeply compare children. If the developer memoizes them, we can
        // use this optimization.
        if (this.props.children !== nextProps.children) return true;
        if (this.props.droppingPosition !== nextProps.droppingPosition) return true;
        // TODO memoize these calculations so they don't take so long?
        const oldPosition = calcGridItemPosition(
            this.getPositionParams(this.props),
            this.props.x,
            this.props.y,
            this.props.w,
            this.props.h,
            this.state
        );
        const newPosition = calcGridItemPosition(
            this.getPositionParams(nextProps),
            nextProps.x,
            nextProps.y,
            nextProps.w,
            nextProps.h,
            nextState
        );
        return (
            !fastPositionEqual(oldPosition, newPosition) ||
            this.props.useCSSTransforms !== nextProps.useCSSTransforms
        );
    }

    componentDidMount() {
        // @ts-ignore
        this.moveDroppingItem({});
    }

    componentDidUpdate(prevProps: GridItemProps) {
        this.moveDroppingItem(prevProps);
    }

    // When a droppingPosition is present, this means we should fire a move event, as if we had moved
    // this element by `x, y` pixels.
    moveDroppingItem(prevProps: GridItemProps) {
        const { droppingPosition } = this.props;
        if (!droppingPosition) return;
        const node = this.elementRef.current;
        // Can't find DOM node (are we unmounted?)
        if (!node) return;

        const prevDroppingPosition = prevProps.droppingPosition || {
            left: 0,
            top: 0
        };
        const { dragging } = this.state;

        const shouldDrag =
            (dragging && droppingPosition.left !== prevDroppingPosition.left) ||
            droppingPosition.top !== prevDroppingPosition.top;

        if (!dragging) {
            this.onDragStart(droppingPosition.e, {
                node,
                deltaX: droppingPosition.left,
                deltaY: droppingPosition.top
            });
        } else if (shouldDrag) {
            const deltaX = droppingPosition.left - dragging.left;
            const deltaY = droppingPosition.top - dragging.top;

            this.onDrag(droppingPosition.e, {
                node,
                deltaX,
                deltaY
            });
        }
    }

    getPositionParams(props: GridItemProps = this.props): PositionParams {
        return {
            cols: props.cols,
            containerPadding: props.containerPadding,
            containerWidth: props.containerWidth,
            margin: props.margin,
            maxRows: props.maxRows,
            rowHeight: props.rowHeight
        };
    }

    /**
     * This is where we set the grid item's absolute placement. It gets a little tricky because we want to do it
     * well when server rendering, and the only way to do that properly is to use percentage width/left because
     * we don't know exactly what the browser viewport is.
     * Unfortunately, CSS Transforms, which are great for performance, break in this instance because a percentage
     * left is relative to the item itself, not its container! So we cannot use them on the server rendering pass.
     *
     * @param  {Object} pos Position object with width, height, left, top.
     * @return {Object}     Style object.
     */
    createStyle(pos: Position): { [key: string]: string } {
        const { usePercentages, containerWidth, useCSSTransforms } = this.props;

        let style;
        // CSS Transforms support (default)
        if (useCSSTransforms) {
            style = setTransform(pos);
        } else {
            // top,left (slow)
            style = setTopLeft(pos);

            // This is used for server rendering.
            if (usePercentages) {
                style.left = perc(pos.left / containerWidth);
                style.width = perc(pos.width / containerWidth);
            }
        }

        return style;
    }

    /**
     * Mix a Draggable instance into a child.
     * @param  {Element} child    Child element.
     * @return {Element}          Child wrapped in Draggable.
     */
    mixinDraggable(
        child: ReactElement<any>,
        isDraggable: boolean
    ): ReactElement<any> {
        return (
            <DraggableCore
                disabled={!isDraggable}
                onStart={this.onDragStart}
                onDrag={this.onDrag}
                onStop={this.onDragStop}
                handle={this.props.handle}
                cancel={".react-resizable-handle"}
                scale={1}
                nodeRef={this.elementRef}
            >
                {child}
            </DraggableCore>
        );
    }

    /**
     * Utility function to setup callback handler definitions for
     * similarily structured resize events.
     */
    curryResizeHandler(position: Position, handler: Function): Function {
        return (e: Event, data: ResizeCallbackData): Function =>
            handler(e, data, position);
    }

    /**
     * Mix a Resizable instance into a child.
     * @param  {Element} child    Child element.
     * @param  {Object} position  Position object (pixel values)
     * @return {Element}          Child wrapped in Resizable.
     */
    mixinResizable(
        child: ReactElement<any>,
        position: Position,
        isResizable: boolean
    ): ReactElement<any> {
        const {
            cols,
            minW,
            minH,
            maxW,
            maxH,
            resizeHandles,
            resizeHandle,
        } = this.props;
        const positionParams = this.getPositionParams();

        // This is the max possible width - doesn't go to infinity because of the width of the window
        const maxWidth = calcGridItemPosition(positionParams, 0, 0, cols, 0).width;

        // Calculate min/max constraints using our min & maxes
        const mins = calcGridItemPosition(positionParams, 0, 0, minW, minH);
        const maxes = calcGridItemPosition(positionParams, 0, 0, maxW, maxH);
        const minConstraints = [mins.width, mins.height];
        const maxConstraints = [
            Math.min(maxes.width, maxWidth),
            Math.min(maxes.height, Infinity)
        ];
        return (
            <Resizable
                // These are opts for the resize handle itself
                draggableOpts={{
                    disabled: !isResizable
                }}
                className={isResizable ? undefined : "react-resizable-hide"}
                width={position.width}
                height={position.height}
                minConstraints={minConstraints}
                maxConstraints={maxConstraints}
                onResizeStop={this.curryResizeHandler(position, this.onResizeStop)}
                onResizeStart={this.curryResizeHandler(position, this.onResizeStart)}
                onResize={this.curryResizeHandler(position, this.onResize)}
                transformScale={1}
                resizeHandles={isResizable ? resizeHandles : []}
                handle={resizeHandle}
            >
                {child}
            </Resizable>
        );
    }

    /**
     * onDragStart event handler
     * @param  {Event}  e             event data
     * @param  {Object} callbackData  an object with node, delta and position information
     */
    onDragStart: (Event, ReactDraggableCallbackData) => void = (e, { node }) => {
        const { onDragStart } = this.props;
        if (!onDragStart) return;

        const newPosition: PartialPosition = { top: 0, left: 0 };

        // TODO: this wont work on nested parents
        const { offsetParent } = node;
        if (!offsetParent) return;
        const parentRect = offsetParent.getBoundingClientRect();
        const clientRect = node.getBoundingClientRect();
        const cLeft = clientRect.left;
        const pLeft = parentRect.left;
        const cTop = clientRect.top;
        const pTop = parentRect.top;
        newPosition.left = cLeft - pLeft + offsetParent.scrollLeft;
        newPosition.top = cTop - pTop + offsetParent.scrollTop;
        this.setState({ dragging: newPosition });

        // Call callback with this data
        const { x, y } = calcXY(
            this.getPositionParams(),
            newPosition.top,
            newPosition.left,
            this.props.w,
            this.props.h
        );

        return onDragStart.call(this, this.props.i, x, y, {
            e,
            node,
            newPosition
        });
    };

    /**
     * onDrag event handler
     * @param  {Event}  e             event data
     * @param  {Object} callbackData  an object with node, delta and position information
     */
    onDrag: (Event, ReactDraggableCallbackData) => void = (
        e,
        { node, deltaX, deltaY }
    ) => {
        const { onDrag } = this.props;
        if (!onDrag) return;

        if (!this.state.dragging) {
            throw new Error("onDrag called before onDragStart.");
        }
        let top = this.state.dragging.top + deltaY;
        let left = this.state.dragging.left + deltaX;

        const { i, w, h } = this.props;
        const positionParams = this.getPositionParams();

        const newPosition: PartialPosition = { top, left };
        this.setState({ dragging: newPosition });

        // Call callback with this data
        const { containerPadding } = this.props;
        const { x, y } = calcXY(
            positionParams,
            top - containerPadding[1],
            left - containerPadding[0],
            w,
            h
        );
        return onDrag.call(this, i, x, y, {
            e,
            node,
            newPosition
        });
    };

    /**
     * onDragStop event handler
     * @param  {Event}  e             event data
     * @param  {Object} callbackData  an object with node, delta and position information
     */
    onDragStop: (Event, ReactDraggableCallbackData) => void = (e, { node }) => {
        const { onDragStop } = this.props;
        if (!onDragStop) return;

        if (!this.state.dragging) {
            throw new Error("onDragEnd called before onDragStart.");
        }
        const { w, h, i, containerPadding } = this.props;
        const { left, top } = this.state.dragging;
        const newPosition: PartialPosition = { top, left };
        this.setState({ dragging: null });

        const { x, y } = calcXY(
            this.getPositionParams(),
            top - containerPadding[1],
            left - containerPadding[0],
            w,
            h
        );

        return onDragStop.call(this, i, x, y, {
            e,
            node,
            newPosition
        });
    };

    /**
     * onResizeStop event handler
     * @param  {Event}  e             event data
     * @param  {Object} callbackData  an object with node and size information
     */
    onResizeStop: GridItemResizeCallback = (e, callbackData, position) =>
        this.onResizeHandler(e, callbackData, position, "onResizeStop");

    // onResizeStart event handler
    onResizeStart: GridItemResizeCallback = (e, callbackData, position) =>
        this.onResizeHandler(e, callbackData, position, "onResizeStart");

    // onResize event handler
    onResize: GridItemResizeCallback = (e, callbackData, position) =>
        this.onResizeHandler(e, callbackData, position, "onResize");

    /**
     * Wrapper around resize events to provide more useful data.
     */
    onResizeHandler(
        e: Event,
        { node, size, handle }: ResizeCallbackData, // 'size' is updated position
        position: Position, // existing position
        handlerName: string
    ): void {
        const handler = this.props[handlerName];
        if (!handler) return;
        const { x, y, i, maxH, minH, containerWidth } = this.props;
        const { minW, maxW } = this.props;

        // Clamping of dimensions based on resize direction
        let updatedSize = size;
        if (node) {
            updatedSize = resizeItemInDirection(
                handle,
                position,
                size,
                containerWidth
            );
            this.setState({
                resizing: handlerName === "onResizeStop" ? null : updatedSize
            });
        }

        // Get new XY based on pixel size
        let { w, h } = calcWH(
            this.getPositionParams(),
            updatedSize.width,
            updatedSize.height,
            x,
            y,
            handle
        );

        // Min/max capping.
        // minW should be at least 1 (TODO propTypes validation?)
        w = clamp(w, Math.max(minW, 1), maxW);
        h = clamp(h, minH, maxH);

        handler.call(this, i, w, h, { e, node, size: updatedSize, handle });
    }

    render(): ReactNode {
        const {
            x,
            y,
            w,
            h,
            isDraggable,
            isResizable,
            droppingPosition,
            useCSSTransforms
        } = this.props;

        const pos = calcGridItemPosition(
            this.getPositionParams(),
            x,
            y,
            w,
            h,
            this.state
        );
        const child = Children.only(this.props.children) as ReactElement;

        // Create the child element. We clone the existing element but modify its className and style.
        let newChild = cloneElement(child, {
            ref: this.elementRef,
            className: classNames(
                "react-grid-item",
                child.props.className,
                this.props.className,
                {
                    static: this.props.static,
                    resizing: Boolean(this.state.resizing),
                    "react-draggable": isDraggable,
                    "react-draggable-dragging": Boolean(this.state.dragging),
                    dropping: Boolean(droppingPosition),
                    cssTransforms: useCSSTransforms
                }
            ),
            // We can set the width and height on the child, but unfortunately we can't set the position.
            style: {
                ...this.props.style,
                ...child.props.style,
                ...this.createStyle(pos)
            },
            x: this.props.x,
            y: this.props.y,
        });

        // Resizable support. This is usually on but the user can toggle it off.
        newChild = this.mixinResizable(newChild, pos, isResizable);

        // Draggable support. This is always on, except for with placeholders.
        newChild = this.mixinDraggable(newChild, isDraggable);

        return newChild;
    }
}